var settings = {
	revealBtnY: 1000,
	btnLabel: 'top',
	icon: 'pbi-angle-down'
};

var state = {
	isBtnHidden: true
};

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom;

var setupDom = function() {

	dom = {};
	dom.body = document.getElementsByTagName('body')[0];
	dom.backToTopBtn = document.getElementsByClassName('back-to-top')[0];

};

var refreshDom = function() {
	setupDom();
};

var configureSettings = function(opts) {
	$.extend(settings, opts);

	console.log('settings');
	console.log(settings);
};

var getBtnMarkup = function() {
	return '<div class="back-to-top back-to-top--hidden">' +
			   '<i class="' + settings.icon +'"></i>' +
			   '<span>' + settings.btnLabel + '</span>' +
		   '</div>';
};

var injectBtn = function() {

	var btnMarkup = getBtnMarkup();

	console.log('body');
	console.log(dom.body);

	dom.body.insertAdjacentHTML('beforeend', btnMarkup);

};

var revealBtn = function() {

	dom.backToTopBtn.classList.remove('back-to-top--hidden');

	state.isBtnHidden = false;

};

var hideBtn = function() {

	dom.backToTopBtn.classList.add('back-to-top--hidden');

	state.isBtnHidden = true;

};

var addEventHandlers = function() {

	window.document.addEventListener('scroll', function() {

		console.log('back to top scroll listener');

		if (state.isBtnHidden && window.scrollY > settings.revealBtnY) {

			revealBtn();

		}

		else if (!state.isBtnHidden && window.scrollY < settings.revealBtnY) {

			hideBtn();

		}

	});

	window.document.addEventListener('click', function(e) {

		var el = e.target;
		var parent = e.target.parentElement;

		if (el.className === 'back-to-top' || parent.className === 'back-to-top') {
			window.scrollTo(0, 0);
		}

	});

};



var init = function(opts) {

	console.log('opts');
	console.log(opts);
	configureSettings(opts);

	setupDom();

	injectBtn();

	refreshDom();

	addEventHandlers();

};



module.exports = {
	init: init
}